<template>
  <div class="wrapper">
    <parallax class="section page-header"
              parallax-active="true"
              :style="headerStyle">
      <div class="game-logo-wrap md-layout">
        <div class="game-head md-layout-item md-size-50 md-small-size-100">
          <img class="game-logo" :src="image_head_title" alt="blast">
        </div>
        <div class="game-head md-layout-item md-size-50 md-small-size-100">
          <img class="game-logo" :src="image_head_character" alt="blast">
        </div>
      </div>
      <div class="game-down-links">
        <a href="https://play.google.com/store/apps/details?id=com.playmetachain.anipangcoins">
          <img :src="pre_order_page_google_btn" alt="google_btn">
        </a>
        <a href="https://apps.apple.com/app/id6443441145">
          <img :src="pre_order_page_app_btn" alt="app_btn">
        </a>
      </div>
    </parallax>
    <div class="main">

      <div class="event section section-cards">
        <div class="container">
          <div class="section-description">
            <h1 class="game-title">
              <span>{{language.games_coins_event_title_1}}</span> {{language.games_coins_event_title_2}}
            </h1>
            <h3 class="game-description">
              {{language.games_coins_event_description}}
            </h3>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <img class="photo_image" :src="sections.event.rewards" alt="slot_machine">
            </div>
          </div>
        </div>
      </div>

      <div class="intro section section-cards">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100 text-center">
              <img :src="sections.intro.slot_machine" alt="slot_machine">
            </div>
            <div class="section-contents md-layout-item md-size-50 md-small-size-100 text-center mx-auto">
              <div class="section-description">
                <h1 class="game-title">
                  {{language.games_coins_intro_title}}
                </h1>
                <h3 class="game-description">
                  {{language.games_coins_intro_description}}
                </h3>
                <md-button class="game-button" @click="goLink">
                  {{language.games_coins_intro_button}}
                </md-button>
              </div>
              <img class="bubble1" :src="sections.intro.coin_1"/>
              <img class="bubble2" :src="sections.intro.coin_2"/>
            </div>
          </div>
        </div>
      </div>

      <div class="moreTokens section section-cards">
        <div class="container">
          <div class="md-layout">
            <div class="section-contents md-layout-item md-size-50 md-small-size-100 mx-auto">
              <br><br>
              <img class="bubble1" :src="sections.intro.coin_1"/>
              <img class="bubble2" :src="sections.intro.coin_2"/>
              <img class="bubble3" :src="sections.intro.coin_3"/>
              <div class="section-description">
                <h1 class="game-title">
                  {{language.games_coins_moreTokes_title}}
                </h1>
                <h3 class="game-description">
                  {{language.games_coins_moreTokes_description}}
                </h3>
              </div>
              <br><br>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <img :src="sections.moreTokens.photo" alt="photo">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Mixins from "@/plugins/basicMixins";

  export default {
    bodyClass: "ac-games-coins",
    mixins: [Mixins.HeaderImage],
    beforeMount() {
      if (this.base.isDomestic) {
        this.$router.replace('/games/pre-register');
      }
    },
    components: {},
    computed: {
      ...mapGetters([
        'base',
        'language',
      ]),
    },
    methods: {
      goLink() {
        window.open('https://wemade-play.gitbook.io/anipang-club/', '_blank');
      }
    },
    data() {
      return {
        image_head_bg: require("@/assets/img/pages/games/coins/coins_main_bg.jpg"),
        image_head_title: require("@/assets/img/pages/games/coins/bi.png"),
        image_head_character: require("@/assets/img/pages/games/coins/main_ani.png"),

        pre_order_page_app_btn: require("@/assets/img/pages/games/common/pre_order_page_app_btn.png"),
        pre_order_page_google_btn: require("@/assets/img/pages/games/common/pre_order_page_google_btn.png"),

        sections: {
          intro: {
            coin_1: require("@/assets/img/pages/games/coins/photo_coin-1.png"),
            coin_2: require("@/assets/img/pages/games/coins/photo_coin-2.png"),
            coin_3: require("@/assets/img/pages/games/coins/photo_coin-3.png"),
            slot_machine: require("@/assets/img/pages/games/coins/slot_machine_image.png"),
          },
          moreTokens: {
            coin_1: require("@/assets/img/pages/games/coins/photo_coin-1.png"),
            coin_2: require("@/assets/img/pages/games/coins/photo_coin-2.png"),
            coin_3: require("@/assets/img/pages/games/coins/photo_coin-3.png"),
            photo: require("@/assets/img/pages/games/coins/photo_image.png"),
          },
          event: {
            rewards: require("@/assets/img/pages/games/coins/website_coins_EVENT-1_rewards_1600x900.png"),
          }
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  @font-face {
    font-family: 'yg-jalnan';
    src: url('../../assets/fonts/Jalnan.ttf') format('truetype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
    font-weight: normal;
    font-display: 'auto';
    font-style: normal;
  }


  /*<img src="/static/frontend/images/info-card-1.jpg" alt="">*/

  $ac-section-bg: #0085ec !default;

  @media (max-width: 980px) {
    .intro {
      .bubble1 {
        display: none;
      }
      .bubble2 {
        display: none;
      }
    }
  }

  .ac-games-coins {
    .page-header {
      height: auto;
      position: relative;

      .game-down-links {
        position: absolute;
        bottom: 15%;
        display: flex;
        justify-content: center;

        a {
          display: block;
          width: 50%;
          text-align: center;

          img {
            width: 90%;
          }
        }
      }
    }

    .game-logo-wrap {
      width: 100%;
      display: flex;
      align-items: center;

      .game-logo {
        width: 100%;
      }
    }

    .main {

      .event {
        background-image: url("../../assets/img/pages/games/coins/website_coins_EVENT_bg_1600x900.jpg");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        text-align: center;

        .section-contents {
          height: 360px;
        }

        .game-title {
          span {
            color: #4542d5;
          }
        }
        .game-description {
          font-family: 'yg-jalnan';
          color: #323179 !important;
        }
      }

      .intro {
        .container {
          .section-contents {}
          .section-description {
            text-align: left;
            position: relative;
            max-width: 700px;
            z-index: 1;
            margin: 0 auto;
          }
        }
        /*.section-description {*/
          /*position: relative;*/
          /*z-index: 1;*/
        /*}*/
        .bubble1 {
          position: absolute;
          z-index: 0;
          right: -40px;
        }
        .bubble2 {
          position: absolute;
          z-index: 0;
          right: -200px;
          bottom: 0;
        }
        .bubble3 {
          position: absolute;
          z-index: 0;
          right: -40px;
        }
      }

      .moreTokens {
        padding: 0;
        overflow: hidden;

        .container {
          .section-contents {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            position: relative;
          }

          .section-description {
            text-align: left;
            position: relative;
            z-index: 1;
            max-width: 700px;
            margin: 0 auto;

            .game-description {
              font-weight: 400;
            }
          }
        }

        .bubble1 {
          position: absolute;
          z-index: 0;
          right: -40px;
        }
        .bubble2 {
          position: absolute;
          z-index: 0;
          left: -150px;
          bottom: -150px;
        }
        .bubble3 {
          position: absolute;
          z-index: 0;
          left: -80px;
        }
      }



      .section:nth-child(2n) {
        background-color: $ac-section-bg;

        .section-description {
          text-shadow: 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg, 0 0 4px $ac-section-bg;
          -webkit-font-smoothing: antialiased;

          .game-title {
            font-family: 'yg-jalnan';
            color: white;
            font-size: min(max(100%, 10vw), 300%);
          }
          .game-description {
            color: white;
            font-size: min(max(50%, 5vw), 150%);
            /*font-size: 5vw;*/
          }
        }
      }

      .section {
        background-color: white;

        .section-contents {
          display: flex;
          flex-wrap: wrap;
          align-content: center;
        }

        .section-description {
          text-shadow: 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white, 0 0 4px white;
          -webkit-font-smoothing: antialiased;

          .game-title{
            font-family: 'yg-jalnan';
            color: $ac-section-bg;
            font-size: min(max(100%, 10vw), 300%);
          }
          .game-description{
            color: $ac-section-bg;
            font-size: min(max(50%, 5vw), 150%);
          }
        }
      }

      .game-button {
        background-color: #004fab !important;
        background-image: linear-gradient(180deg, #006cbf 50%, #006cbf 50%, #004fab );

        background-repeat: repeat-x;

        display: inline-block;
        outline: none;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        font-family: 'yg-jalnan';
        color: white;
        font-size: min(max(100%, 5vw), 200%);

        padding: 0.1em 0.5em;
        text-shadow: 0 1px 1px rgba(0,0,0,.3);

        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        border-radius: 5rem;
        border: 5px solid #fff;

        -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
        box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
      }
    }

  }
</style>
